import React from "react";
import logo from '../logo.svg';


export default function ValidRegistration() {

    return (
        <section className="section-register">
            <h2>Votre Inscription est terminée !</h2>
            
            <img src={logo} alt="logo"></img>

            <h3>Merci de compter parmi les membres du site <strong>Ma Liste de Films</strong> !</h3>
            <h3>Ce site continue d'évoluer petit à petit et a pour but de me permettre de m'améliorer et de comprendre davantage le développement d'applications web.</h3>
            <h3>Si vous êtes intéressé par mon travail, retrouvez mon portfolio en <a target="_blank" rel="noopener noreferrer" href="https://www.maxence-bureau.fr">cliquant ici</a>.</h3>
            <h3>En cas de questions, remarques ou remontées de bugs n'hésitez pas à me contacter à mon adresse mail : <a href="mailto:maxencebureau0@gmail.com">maxencebureau0@gmail.com</a></h3>

            <a href="/login" className="home-btn">Me connecter</a>
        </section>
    )

}