import React, { useState } from "react";
import ModalProfil from "./ModalProfil";
import logo from '../logo.svg';
import ListeProfil from "../components/ListeProfil";

export default function Profil(props){
    const [jours, setJours] = useState("")
    const [heures, setHeures] = useState("")
    const [minutes, setMinutes] = useState("")
    const [nbrFilms, setNbrFilms] = useState("")

    const [Modal, setModal] = useState(false); 

    const affichageInfos = (nbrFilms, duration) =>{
        let temps = duration
        let jours
        let heures
        let minutes
        heures = Math.floor(temps / 60)
        if(heures > 24){
            jours = Math.round(heures/24)
            heures = heures%24
        }
        minutes = (temps%60)

        setJours(jours)
        setHeures(heures)
        setMinutes(minutes)

        setNbrFilms(nbrFilms)
    }

    const showModal = () =>{
        setModal(true)
        document.body.style.overflow = 'hidden'
    }

    const hideModal = () =>{
        setModal(false)
        document.body.style.overflow = 'unset';
    }

    return (
        props.user ?(
            <>
                <section className="section-profil">

                    {Modal ? <ModalProfil return={hideModal}infos={props.user}/> : null}
                    <svg className="edit-svg edit-mobile" onClick={showModal} viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m21.816 5.114c-0.0962-0.42328-0.5818-0.56671-0.8645-0.25828l-3.1952 3.4856-2.9167-0.53016-0.486-3.1819 3.1952-3.4856c0.2844-0.31031 0.1474-0.83906-0.2432-0.945-2.0359-0.55031-4.2775 0.042656-5.8687 1.778-1.7033 1.8581-2.172 4.5516-1.4631 6.9l-9.1691 10.003c-1.0738 1.1714-1.0738 3.0707 0 4.2422 1.0738 1.1714 2.8149 1.1714 3.8887 0l9.1614-9.9943c2.1536 0.7833 4.6178 0.2663 6.3323-1.604 1.5928-1.7377 2.1355-4.1869 1.6289-6.4092zm-19.066 17.011c-0.56934 0-1.0312-0.5039-1.0312-1.125 0-0.6216 0.46191-1.125 1.0312-1.125s1.0312 0.5034 1.0312 1.125c0 0.6211-0.46191 1.125-1.0312 1.125z"/>
                    </svg>
                    
                    <div className="div-modal-film">
                        <img className="membres-avatar" alt={"avatar de " + props.user.name} src={"https://res.cloudinary.com/maliste/image/upload/v1633083902/avatars/" + props.user.avatar}></img>
                        <div>
                            <div className="modal-membres-infos">
                                <svg className="edit-svg edit-desktop" onClick={showModal} viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m21.816 5.114c-0.0962-0.42328-0.5818-0.56671-0.8645-0.25828l-3.1952 3.4856-2.9167-0.53016-0.486-3.1819 3.1952-3.4856c0.2844-0.31031 0.1474-0.83906-0.2432-0.945-2.0359-0.55031-4.2775 0.042656-5.8687 1.778-1.7033 1.8581-2.172 4.5516-1.4631 6.9l-9.1691 10.003c-1.0738 1.1714-1.0738 3.0707 0 4.2422 1.0738 1.1714 2.8149 1.1714 3.8887 0l9.1614-9.9943c2.1536 0.7833 4.6178 0.2663 6.3323-1.604 1.5928-1.7377 2.1355-4.1869 1.6289-6.4092zm-19.066 17.011c-0.56934 0-1.0312-0.5039-1.0312-1.125 0-0.6216 0.46191-1.125 1.0312-1.125s1.0312 0.5034 1.0312 1.125c0 0.6211-0.46191 1.125-1.0312 1.125z"/>
                                </svg>
                                <h3>{props.user.name}</h3>
                                {props.user.description ? (
                                    <h4><strong>"</strong>{props.user.description}<strong>"</strong></h4>) 
                                : (
                                    <h4><strong>"</strong>Utilisateur de Ma Liste<strong>"</strong></h4>
                                )}
                            </div>
                            <div className="modal-film-infos">
                            <img alt="logo" src={logo}></img>
                            <h4>{nbrFilms} <br /> Films Vus</h4>
                        </div>
                        <div className="modal-film-infos">
                            <img alt="tempslogo" src={`${process.env.PUBLIC_URL}/icons/temps.svg`}></img>
                            <div className="div-temps">
                                <h4>{
                                    jours ? (
                                        jours <= 1 ? (
                                            jours + " jour "
                                        ) : (
                                            jours + " jours "
                                        )
                                    ) : null
                                }</h4>
                                <h4>{
                                    heures ? (
                                        heures <= 1 ? (
                                            heures + " heure "
                                        ) : (
                                            heures + " heures "
                                        )
                                    ) : null
                                }</h4>
                                <h4>{
                                    minutes ? (
                                        minutes <= 1 ?(
                                            minutes + " minute."
                                        ):(
                                            minutes + " minutes"
                                        )
                                    ) : null    
                                }</h4>
                            </div>
                        </div>
                        </div>
                    </div>
                
                </section>
                <section className="section-liste">
                    <ListeProfil loading={props.loading} loginStatus={props.user} resultatInfos={affichageInfos}/>
                </section>
            </>
        
        ) : (
            <section className="section-renvoi">
                <h2>Vous n'êtes pas en ligne</h2>
                <p>Pour accéder à cette page vous devez être connecté.</p>
                <a href="/login" className="home-btn">Se connecter</a>
            </section>
        )
    )
}