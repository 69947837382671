import React, { useEffect, useState } from "react";
import Axios from "axios"

export default function ModalFilm(props) {
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    const [scoreUser, setScoreUser] = useState("");
    const [formStatus, setFormStatus] = useState("");

    Axios.defaults.withCredentials = true;

    useEffect(() => {
        let modalSection = document.getElementById("section-modal")
        modalSection.scrollIntoView()
    })

    const maxScoreCheck = (score) =>{
        let scoreFinal = score.target.value
        if(score.target.value > 20){
            scoreFinal = 20
        }
        if(score.target.value < 0){
            scoreFinal = 1
        }
        setScoreUser(scoreFinal)
    }

    const mark = ()=>{
        Axios.post(serverAdress + "mark",
        {idFilm: props.infos.id,
        score: scoreUser}).then((response)=>{
            if(response.data.message){
                setFormStatus(response.data.message)
            }
            else{
                props.return()
                props.deleteFilm(props.infos.id)
            }
        })
    }



    return (
        <section id="section-modal" className="modal-film">
            <svg onClick={props.return} className="return-svg" viewBox="0 0 26 36" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#a)">
                    <path d="m4.6129 12.513 11.844-11.896c0.8186-0.82224 2.1423-0.82224 2.9522 0l1.9682 1.9769c0.8186 0.82225 0.8186 2.1518 0 2.9653l-8.3866 8.4411 8.3953 8.4324c0.8187 0.8222 0.8187 2.1518 0 2.9653l-1.9682 1.9856c-0.8186 0.8223-2.1423 0.8223-2.9522 0l-11.844-11.896c-0.82733-0.8222-0.82733-2.1518-0.0087-2.974z" />
                </g>
                <defs>
                    <filter id="a" x="0" y="0" width="26" height="36" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
            <div className="div-modal-film">
                <img src={props.infos.poster} alt={"affiche de " + props.infos.titre}></img>
                <div>
                    <h3>{props.infos.titre}</h3>
                    <div className="div-modal-score">
                        <svg fill="#EFC031" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                            <path d="m8 0 1.7961 5.5279h5.8124l-4.7023 3.4164 1.7961 5.5278-4.7023-3.4164-4.7023 3.4164 1.7961-5.5278-4.7023-3.4164h5.8123l1.7961-5.5279z" fill="#EFC031" />
                        </svg>
                        <p>{props.infos.Score ? (props.infos.Score) : props.infos.note}</p>
                    </div>
                    <div className="div-modal-infos">
                        <p>Date de sortie : {props.infos.year}</p>
                        <p>Durée : {Math.floor(props.infos.duration / 60)} h {props.infos.duration % 60}</p>
                        <p>Réalisé par : {props.infos.director}</p>
                        {props.loginStatus ? (
                            <>
                                <p>Ma note :</p>
                                <h4>{formStatus}</h4>
                                <form className="form-modal">
                                    <input type="number" name="note" min="0" max="20" placeholder={props.infos.Score || 10} value={scoreUser || ""}required onChange={(e) => { maxScoreCheck(e) }}></input>
                                    <button type="button" id='formLogin' className='formLogin' onClick={mark}>✓</button>
                                </form>
                            </>
                        ) : null}

                    </div>
                </div>
            </div>
        </section>
    )

}