import React, { useState } from "react";
import Axios from "axios"
import { useHistory } from "react-router";

export default function Login(props) {
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;
    let history = useHistory()
    const [pseudoUserLogin, setPseudoUserLogin] = useState("");
    const [mdpUserLogin, setMdpUserLogin] = useState("");

    const [loginStatus, setLoginStatus] = useState("");

    Axios.defaults.withCredentials = true;

    const login = ()=>{
        Axios.post(serverAdress + "login",
        {username: pseudoUserLogin,
        pwd: mdpUserLogin}).then((response)=>{
            if(response.data.message){
                setLoginStatus(response.data.message)
            }
            else{
                setLoginStatus(response.data[0].name)
                props.userConnect(response.data[0])
                history.push("/")
            }
        })
    }

    return (
        <section className="section-login">
            <h2>Connexion</h2>
            <h3>{loginStatus}</h3>
            <form>
                <div className="inputlabel">
                    <input className="inputform" type="text" id="pseudoUser" name="pseudoUser" placeholder=" " required onChange={(e)=>{setPseudoUserLogin(e.target.value)}}></input>
                    <label className="labelform" htmlFor="pseudoUser">Pseudo</label>
                </div>
                <div className="inputlabel">
                    <input className="inputform" type="password" id="mdpUser" placeholder=" " name="mdpUser" required onChange={(e)=>{setMdpUserLogin(e.target.value)}}></input>
                    <label className="labelform" htmlFor="mdpUser">Mot de passe</label>
                </div>
                <button type="button" id="formLogin" className="home-btn" onClick={login}>Se connecter</button>
            </form>
            <a href="/register" className="home-btn btn-register">S'inscrire</a>
        </section>
    )
}