export default function Admin(props){
    return (
         props.user.droits === "admin" || props.user.droits === "modo" ? (
            <section className="section-login">
                <a href="/adminAdd" className="home-btn">Ajouter un film</a>
                {props.user.droits === "admin" ? (
                    <>
                        <a href="/adminValid" className="home-btn">Valider un film</a>
                        <a href="/adminPerm" className="home-btn">Permissions</a>
                    </>
                ) : null}
            </section>
        ) : (
            <section className="section-renvoi">
            <h2>Vous n'êtes pas en ligne ou vous n'avez pas les permissions nécessaires</h2>
            <p>Pour accéder à cette page vous devez être connecté et posséder les droits d'administration.</p>
            <a href="/login" className="home-btn">Se connecter</a>
        </section>
        )
    )
}