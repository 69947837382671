import React, { useState } from "react";
import logo from '../logo.svg';
import ListeHome from "../components/ListeHome";
import Axios from "axios"


export default function Home(props){

    const [nbrFilms, setNbrFilms] = useState([]);
    const [nbrUtilisateurs, setNbrUtilisateurs] = useState([]);
    const [nbrFilmsVus, setNbrFilmsVus] = useState([]);

    Axios.defaults.withCredentials = true;

    const affichageInfos = (nbrFilmsTmp, nbrUtilisateursTmp, nbrFilmsVusTmp) =>{
        setNbrFilms(nbrFilmsTmp)
        setNbrUtilisateurs(nbrUtilisateursTmp)
        setNbrFilmsVus(nbrFilmsVusTmp)
    }

    return (
        <>
        <section className="section-home">
            <div className="home-pres">
                <img src={logo} alt="logo"></img>
                <h1>Ma Liste de Films</h1>
                <h2>Le site de notation pour les gens qui ont du goût</h2>
            </div>
            <div className="home-barre"></div>
            <div className="home-infos">
                <p><strong>{nbrFilms}</strong> Films enregistrés</p>
                <p><strong>{nbrUtilisateurs}</strong> Membres Inscrits</p>
                <p><strong>{nbrFilmsVus}</strong> Films regardés</p>

                {props.user ? null : (
                    <a href="/login" className="home-btn">Rejoins Nous !</a>
                )}
            </div>
        </section>
        <section className="section-liste">
            <ListeHome loading={props.loading} loginStatus={props.user} resultatInfos={affichageInfos}/>
        </section>
        </>
    )
}