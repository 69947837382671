import React, { useState } from "react";
import Axios from "axios"
import { useHistory } from "react-router";

export default function ModalProfil(props){
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    const [previewAvatar, setPreviewAvatar] = useState("");
    const [description, setDescription] = useState("");

    let history = useHistory();

    Axios.defaults.withCredentials = true;

    const AvatarEnvoi = (e) =>{
        const fichierTemp = e.target.files[0];
        affichageAvatar(fichierTemp)
    }

    const affichageAvatar = (fichierTemp) =>{
        const reader = new FileReader()
        reader.readAsDataURL(fichierTemp)
        reader.onloadend = ()=>{
            setPreviewAvatar(reader.result)
        }
    }

    const changeDescription = ()=>{
        Axios.post(serverAdress + "changeDescription",
        {description: description}).then((response)=>{
            if(response.data.message){
                console.log(response.data.message)
            }
            if(response.data.isConnect === false){
                history.push("/login")
            }
            else{
                props.return()
            }
        })
    }

    const uploadAvatar = ()=>{
        if(previewAvatar){
            Axios.post(serverAdress + "uploadAvatar",
            {avatar: previewAvatar}).then((response)=>{
                if(response.data.message){
                    console.log(response.data.message)
                }
                if(response.data.isConnect === false){
                    history.push("/login")
                }
                else{
                    props.return()
                }
            })
        }
    }

    return (
        <section className="modal-film modal-profil">
            <svg onClick={props.return} className="return-svg" viewBox="0 0 26 36" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#a)">
                    <path d="m4.6129 12.513 11.844-11.896c0.8186-0.82224 2.1423-0.82224 2.9522 0l1.9682 1.9769c0.8186 0.82225 0.8186 2.1518 0 2.9653l-8.3866 8.4411 8.3953 8.4324c0.8187 0.8222 0.8187 2.1518 0 2.9653l-1.9682 1.9856c-0.8186 0.8223-2.1423 0.8223-2.9522 0l-11.844-11.896c-0.82733-0.8222-0.82733-2.1518-0.0087-2.974z" />
                </g>
                <defs>
                    <filter id="a" x="0" y="0" width="26" height="36" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
            <form className="form-avatar">
                {previewAvatar ? (
                    <img alt="avatar futur" className="membres-avatar" src={previewAvatar}></img>
                ) : (
                    <img className="membres-avatar" alt={"avatar de " + props.infos.name} src={"https://res.cloudinary.com/maliste/image/upload/v1633083902/avatars/" + props.infos.avatar}></img>
                )}
                    <label htmlFor="inputAvatar"></label>
                    <input id="inputAvatar" accept=".png,.jpg,.gif" type="file" name="avatar" onChange={AvatarEnvoi}></input>
                    <button type="button" id='formLogin' className='formLogin' onClick={uploadAvatar}>✔</button>
            </form>
            <form className="form-modal-profil">
                <label htmlFor="form-description">Modifier ma description</label>
                <div className="form-modal">
                    <input id="form-description" type="text" placeholder={props.infos.description} onChange={(e)=>{setDescription(e.target.value)}}></input>
                    <button type="button" onClick={changeDescription}>✔</button>
                </div>
            </form>
        </section>

    )
}