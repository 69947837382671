export default function AdminPerm(props){
    return (
         props.user.droits === "admin" ? (
            <section className="section-login">
                <h1>Hello</h1>
            </section>
        ) : (
            <section className="section-renvoi">
            <h2>Vous n'êtes pas en ligne ou vous n'avez pas les permissions nécessaires</h2>
            <p>Pour accéder à cette page vous devez être connecté et posséder les droits d'administration.</p>
            <a href="/login" className="home-btn">Se connecter</a>
        </section>
        )
    )
}