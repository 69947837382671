import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios"

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Membres from "./components/Membres";
import Profil from "./components/Profil";
import Login from "./components/Login";
import ValidRegistration from "./components/ValidRegistration";
import Loading from "./components/Loading";
import Admin from "./components/Admin";
import AdminAdd from "./components/AdminAdd";
import AdminValid from "./components/AdminValid";
import AdminPerm from "./components/AdminPerm";
import Register from "./components/Register"; //On importe les pages

function App() {  
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const serverAdress = process.env.REACT_APP_SERVEUR_URL;
  Axios.defaults.withCredentials = true;

  const userConnect = (data) =>{
    setUser(data)
  }

  useEffect(()=>{
    Axios.get(serverAdress + "login").then((response)=>{
        if(response.data.loggedIn === true){
          userConnect(response.data.user[0])
        }
    })
}, [serverAdress])

  return (
    <>
      {loading ? (
        <Loading/>
      ) : null}
      <BrowserRouter>
        <Navbar user={user}/>
        <Switch>
          <Route exact path="/">
            <Home user={user} loading={setLoading}/>
          </Route>
          <Route exact path="/profil">
            <Profil user={user} loading={setLoading}/>
          </Route>
          <Route exact path="/membres">
            <Membres loading={setLoading} />
          </Route>
          <Route component={Membres} exact path="/membres" />
          <Route exact path="/login">
            <Login userConnect={userConnect}/>
          </Route>
          <Route component={Register} exact path="/register" />
          <Route component={ValidRegistration} exact path="/ValidRegistration" />
          <Route exact path="/Admin">
            <Admin user={user}/>
          </Route>
          <Route exact path="/AdminAdd">
            <AdminAdd user={user}/>
          </Route>
          <Route exact path="/AdminValid">
            <AdminValid user={user}/>
          </Route>
          <Route exact path="/AdminPerm">
            <AdminPerm user={user}/>
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );// Mise en place des routes
}


export default App;
