import React, { useEffect, useState } from "react";
import Axios from "axios"
import FicheMembres from "./FicheMembres";

export default function Membres(props){
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    const [membres, setMembres] = useState([]);
    Axios.defaults.withCredentials = true;

    useEffect(()=>{
        Axios.get(serverAdress + "membres").then((response)=>{
            if(response.data.message){
                console.log(response.data.message);
            }
            else{
                response.data.forEach(membre => {
                    setMembres(membres => [...membres,membre])
                });
            }
        })
    }, [serverAdress])

    return (
        <section className="section-membres">
            <h2>Découvrir nos membres</h2>
            <div className="div-membres">
                {membres.map(membre => (
                    <FicheMembres key={membre.id} loading={props.loading} infos={membre}/>
                ))}
            </div>
        </section>
    )
}