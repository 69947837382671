import React, { useEffect, useState } from "react";
import Axios from "axios"
import logo from '../logo.svg';

export default function ModalMembre(props){
    const loading = props.loading;
    const infos = props.infos
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;
    
    useEffect(() => {
        let modalSection = document.getElementById("section-modal")
        modalSection.scrollIntoView()
    })

    const [jours, setJours] = useState("")
    const [heures, setHeures] = useState("")
    const [minutes, setMinutes] = useState("")
    const [nbrFilms, setNbrFilms] = useState("")
    Axios.defaults.withCredentials = true;

    useEffect(()=>{
        Axios.get(serverAdress + "membres/" + infos.id).then((response)=>{
            if(response.data.message){
                console.log(response.data.message);
            }
            else{
                let temps = response.data[0].temps
                let jours
                let heures
                let minutes
                heures = Math.floor(temps / 60)
                if(heures > 24){
                    jours = Math.round(heures/24)
                    heures = heures%24
                }
                minutes = (temps%60)

                setJours(jours)
                setHeures(heures)
                setMinutes(minutes)
                setNbrFilms(response.data[1])
            }
            loading(false)
        },
        loading(true))
    }, [serverAdress, loading, infos])

    return (
        <section id="section-modal" className="modal-film">
            <svg onClick={props.return} className="return-svg" viewBox="0 0 26 36" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#a)">
                    <path d="m4.6129 12.513 11.844-11.896c0.8186-0.82224 2.1423-0.82224 2.9522 0l1.9682 1.9769c0.8186 0.82225 0.8186 2.1518 0 2.9653l-8.3866 8.4411 8.3953 8.4324c0.8187 0.8222 0.8187 2.1518 0 2.9653l-1.9682 1.9856c-0.8186 0.8223-2.1423 0.8223-2.9522 0l-11.844-11.896c-0.82733-0.8222-0.82733-2.1518-0.0087-2.974z" />
                </g>
                <defs>
                    <filter id="a" x="0" y="0" width="26" height="36" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
            <div className="div-modal-film">
                <img alt={"avatar de " + infos.name} className="membres-avatar" src={"https://res.cloudinary.com/maliste/image/upload/v1633083902/avatars/" + infos.avatar}></img>
                <div>
                    <div className="modal-membres-infos">
                        <h3>{infos.name}</h3>
                        {infos.description ? (
                            <h4><strong>"</strong>{infos.description}<strong>"</strong></h4>) 
                        : (
                            <h4><strong>"</strong>Utilisateur de Ma Liste<strong>"</strong></h4>
                        )}
                    </div>
                    <div className="modal-film-infos">
                        <img alt="logo" src={logo}></img>
                        <h4>{nbrFilms} <br /> Films Vus</h4>
                    </div>
                    <div className="modal-film-infos">
                        <img alt="tempslogo" src={`${process.env.PUBLIC_URL}/icons/temps.svg`}></img>
                        <div className="div-temps">
                            <h4>{
                                jours ? (
                                    jours <= 1 ? (
                                        jours + " jour "
                                    ) : (
                                        jours + " jours "
                                    )
                                ) : null
                            }</h4>
                            <h4>{
                                heures ? (
                                    heures <= 1 ? (
                                        heures + " heure "
                                    ) : (
                                        heures + " heures "
                                    )
                                ) : null
                            }</h4>
                            <h4>{
                                minutes ? (
                                    minutes <= 1 ?(
                                        minutes + " minute."
                                    ):(
                                        minutes + " minutes"
                                    )
                                ) : null    
                            }</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}