import React, { useEffect, useState } from "react";
import Axios from "axios"
import FicheFilm from "./FicheFilm";
import { useHistory } from "react-router";

export default function ListeHome(props) {
    let history = useHistory()
    const loading = props.loading;
    const resultatInfos = props.resultatInfos;
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    const [films, setFilms] = useState([]);
    Axios.defaults.withCredentials = true;

    useEffect(()=>{
        Axios.get(serverAdress).then((response)=>{
            if(response.data.message){
                console.log(response.data.message);
            }
            if(response.data[0] && response.data[1] && response.data[2] && response.data[3]){
                const nbrFilms = response.data[0]
                const nbrUtilisateurs = response.data[1]
                const nbrFilmsVus = response.data[2]
                response.data[3].forEach(film => {
                    setFilms(films => [...films,film])
                });
                resultatInfos(nbrFilms, nbrUtilisateurs, nbrFilmsVus)
            }
            else{
                history.push("/")
            }
            loading(false)
        },
        loading(true))
    }, [serverAdress, loading])
    
    const deleteFilm = (id) =>{
        let filmId = id
        let filmsTempo = films.slice()

        for(let indice = 0; indice < filmsTempo.length; indice++){
            if(filmsTempo[indice].id === filmId){
                filmsTempo.splice(indice, 1)
                setFilms(filmsTempo)
                break
            }
        }
    }

    return (
        films.map(film => (
            <FicheFilm key={film.id} deleteFilm={deleteFilm} loginStatus={props.loginStatus} infos={film}/>
        ))
    )
}