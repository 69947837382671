import Axios from "axios"
import React, { useEffect, useState } from "react";

export default function AdminValid(props) {
    const user = props.user
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;
    const [films, setFilms] = useState([]);
    const [addStatus, setAddStatus] = useState("");

    const validFilm = (film)=>{
        if(document.querySelector("#poster" + film.id).value){
            let poster = document.querySelector("#poster" + film.id).value
            Axios.post(serverAdress + "validFilm",
            {
                titre: film.titre,
                realisateur: film.director,
                duration: film.duration,
                year: film.year,
                poster: poster,
                idTempo: film.id
            }).then((response)=>{
                if(response.data.message){
                    setAddStatus(response.data.message)
                }
                else{

                }
            })
        }
        else{
            setAddStatus("Pas de lien")
        }
    }

    useEffect(() => {
        if (user.droits === "admin") {
            Axios.get(serverAdress + "validFilm").then((response) => {
                if (response.data.message) {
                    console.log(response.data.message);
                }
                else {
                    response.data.forEach(film => {
                        setFilms(films => [...films, film])
                    });
                }
            })
        }
    }, [serverAdress, user])

    return (
        user.droits === "admin" ? (
            <section className="section">
                <h3>{addStatus}</h3>
                {films.map(film => (
                    <div key={film.id} className="div-film">
                        <div className="film-poster poster-form">
                            <span className="poster-notdefined"></span>
                            <form className="form-poster">
                            <div className="inputlabel">
                                <input className="inputform" type="text" id={"poster" + film.id} name="poster" placeholder=" " required></input>
                                <label className="labelform" htmlFor="poster">URL du poster</label>
                            </div>
                            <button type="button" className="home-btn" onClick={(e)=>{validFilm(film)}}>Valider</button>
                            </form>
                        </div>
                        <div className="film-infos">
                            <h3>{film.titre}</h3>
                            <div>
                                <p>{film.year}</p>
                            </div>
                        </div>
                    </div>
                ))}

            </section>
        ) : (
            <section className="section-renvoi">
                <h2>Vous n'êtes pas en ligne ou vous n'avez pas les permissions nécessaires</h2>
                <p>Pour accéder à cette page vous devez être connecté et posséder les droits d'administration.</p>
                <a href="/login" className="home-btn">Se connecter</a>
            </section>
        )
    )
}