import React, { useState } from "react";
import Axios from "axios"

export default function AdminAdd(props){
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    const [titre, setTitre] = useState("");
    const [realisateur, setRealisateur] = useState("");
    const [duration, setDuration] = useState("");
    const [year, setYear] = useState("");
    const [addStatus, setAddStatus] = useState("");

    Axios.defaults.withCredentials = true;

    const addFilm = ()=>{
        Axios.post(serverAdress + "addFilm",
        {
            titre: titre,
            realisateur: realisateur,
            duration: duration,
            year: year
        }).then((response)=>{
            console.log(response)
            if(response.data.message){
                setAddStatus(response.data.message)
            }
        })
    }
    
    return (
         props.user.droits === "admin" || props.user.droits === "modo" ? (
            <section className="section-login">
                <h2>Ajouter un film</h2>
                <h3>{addStatus}</h3>
                <form>
                    <div className="inputlabel">
                        <input className="inputform" type="text" id="titre" name="titre" placeholder=" " required onChange={(e)=>{setTitre(e.target.value)}}></input>
                        <label className="labelform" htmlFor="titre">Titre (en VO)</label>
                    </div>
                    <div className="inputlabel">
                        <input className="inputform" type="text" id="realisateur" placeholder=" " name="realisateur" required onChange={(e)=>{setRealisateur(e.target.value)}}></input>
                        <label className="labelform" htmlFor="realisateur">Réalisateur</label>
                    </div>
                    <div className="inputlabel">
                        <input className="inputform" type="number" id="duration" placeholder=" " name="duration" required onChange={(e)=>{setDuration(e.target.value)}}></input>
                        <label className="labelform" htmlFor="duration">Durée du film (en minutes)</label>
                    </div>
                    <div className="inputlabel">
                        <input className="inputform" type="number" id="year" placeholder=" " name="year" required onChange={(e)=>{setYear(e.target.value)}}></input>
                        <label className="labelform" htmlFor="year">Année de sortie</label>
                    </div>

                    <button type="button" id="formLogin" className="home-btn" onClick={addFilm}>Ajouter le film</button>
                </form>
            </section>
        ) : (
            <section className="section-renvoi">
            <h2>Vous n'êtes pas en ligne ou vous n'avez pas les permissions nécessaires</h2>
            <p>Pour accéder à cette page vous devez être connecté et posséder les droits d'administration.</p>
            <a href="/login" className="home-btn">Se connecter</a>
        </section>
        )
    )
}