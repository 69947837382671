import React, { useState } from "react";
import ModalMembre from "./ModalMembre";


export default function FicheMembres(props){
    const [Modal, setModal] = useState(false); 

    const showModal = () =>{
        setModal(true)
        document.body.style.overflow = 'hidden'
    }

    const hideModal = () =>{
        setModal(false)
        document.body.style.overflow = 'unset';
    }

    return (
        <>
            {Modal ? <ModalMembre loading={props.loading} return={hideModal}infos={props.infos}/> : null}
            <div className="membre-fiche" onClick={showModal}>
                <img alt={"avatar de " + props.infos.name}src={"https://res.cloudinary.com/maliste/image/upload/v1633083902/avatars/" + props.infos.avatar}></img>
                <h3>{props.infos.name}</h3>
            </div>
        </>
    )
}