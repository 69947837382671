import React, { useState } from "react";
import Axios from "axios"
import { useHistory } from "react-router";

export default function Register(props) {
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    let history = useHistory()

    const [pseudoUserLogin, setPseudoUserLogin] = useState("");
    const [mailUserLogin, setMailUserLogin] = useState("");
    const [mdpUserLogin, setMdpUserLogin] = useState("");
    const [mdpVerify, setMdpVerify] = useState("");
    const [registerStatus, setRegisterStatus] = useState(""); //On crée les status et infos
    
    Axios.defaults.withCredentials = true; // On autorise les credentials

    const register = ()=>{
        if(mdpUserLogin === mdpVerify){
            Axios.post(serverAdress + "register",
            {username: pseudoUserLogin,
            mail: mailUserLogin,
            pwd: mdpUserLogin}).then((response)=>{
                if(response.data.message){
                    setRegisterStatus(response.data.message)
                }
                else if(response.data === true){
                    history.push("/ValidRegistration")
                }
            })
        }
        else{
            setRegisterStatus("Les mots de passe ne correspondent pas")
        }
    } // On envoie les données au serveur

    return (
        <section className="section-register">
        <h2>Inscription</h2>
        <h3>{registerStatus}</h3>
            <form>
                <div className="inputlabel">
                    <input className="inputform" type="text" id="pseudoUser" name="pseudoUser" placeholder=" " required onChange={(e)=>{setPseudoUserLogin(e.target.value)}}></input>
                    <label className="labelform" htmlFor="pseudoUser">Pseudo</label>
                </div>
                
                <div className="inputlabel">
                    <input className="inputform" type="text" id="mailUser" name="mail" placeholder=" " required onChange={(e)=>{setMailUserLogin(e.target.value)}}></input>
                    <label className="labelform" htmlFor="mail">Adresse E-Mail</label>
                </div>

                <div className="inputlabel">
                    <input className="inputform" type="password" id="mdpUser" name="mdpUser" placeholder=" " required onChange={(e)=>{setMdpUserLogin(e.target.value)}}></input>
                    <label className="labelform" htmlFor="mdpUser">Mot de passe</label>
                </div>

                <div className="inputlabel">
                    <input className="inputform" type="password" id="mdpVerify" name="mdpVerify" placeholder=" " required onChange={(e)=>{setMdpVerify(e.target.value)}}></input>
                    <label className="labelform" htmlFor="mdpVerify">Retapez votre mot de passe</label>
                </div>

                <button type="button" id='formLogin' className="home-btn" onClick={register}>S'inscrire</button>
            </form>
        </section>
    )
}