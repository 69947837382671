import React, { useEffect, useState } from "react";
import Axios from "axios"
import { useHistory } from "react-router";
import FicheFilm from "./FicheFilm";

export default function ListeHome(props) {
    const loading = props.loading;
    const resultatInfos = props.resultatInfos;
    const serverAdress = process.env.REACT_APP_SERVEUR_URL;

    const [films, setFilms] = useState([]);
    Axios.defaults.withCredentials = true;

    let history = useHistory();

    useEffect(()=>{
        Axios.get(serverAdress + "profil").then((response)=>{
            if(response.data.message){
                console.log(response.data.message);
            }
            if(response.data.isConnect === false){
                history.push("/login")
            }
            else{
                const nbrFilms = response.data[1]
                const temps = response.data[0]
                response.data[2].forEach(film => {
                    setFilms(films => [...films,film])
                });
                resultatInfos(nbrFilms, temps)
            }
            loading(false)
        },
        loading(true))
    }, [serverAdress, loading, history])
    
    const deleteFilm = (id) =>{
        let filmId = id
        let filmsTempo = films.slice()

        for(let indice = 0; indice < filmsTempo.length; indice++){
            if(filmsTempo[indice].id === filmId){
                filmsTempo.splice(indice, 1)
                setFilms(filmsTempo)
                break
            }
        }
    }

    return (
        films.map(film => (
            <FicheFilm key={film.id} deleteFilm={deleteFilm} loginStatus={props.loginStatus} infos={film}/>
        ))
    )
}