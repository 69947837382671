import React, { useState } from "react";
import ModalFilm from "./ModalFilm";


export default function FicheFilm(props) {

    const [Modal, setModal] = useState(false); 

    const showModal = () =>{
        setModal(true)
        document.body.style.overflow = 'hidden';
        document.body.style.position = "relative";
    }

    const hideModal = () =>{
        setModal(false)
        document.body.style.overflow = 'unset';
        document.body.style.position = "relative";
    }

    return (
        <>
        {Modal ? <ModalFilm loginStatus={props.loginStatus} deleteFilm={props.deleteFilm} return={hideModal}infos={props.infos}/> : null}
        <div className="div-film" onClick={showModal}>
            <div className="film-poster">
                <img src={props.infos.poster} alt={"affiche de " + props.infos.titre}></img>
            </div>
            <div className="film-infos">
                <h3>{props.infos.titre}</h3>
                <div>
                    <div>
                        <svg fill="#EFC031" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                            <path d="m8 0 1.7961 5.5279h5.8124l-4.7023 3.4164 1.7961 5.5278-4.7023-3.4164-4.7023 3.4164 1.7961-5.5278-4.7023-3.4164h5.8123l1.7961-5.5279z" fill="#EFC031" />
                        </svg>
                        <p>{props.infos.Score ? (props.infos.Score) : props.infos.note}</p>
                    </div>
                    <p>{props.infos.year}</p>
                </div>
            </div>
        </div>
        </>
    )

}