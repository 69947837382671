export default function Loading(){
    return (
        <section className="section-loading">
            <div>
                <span className="loader"></span>
                <div className="loaders">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </section>
    )
}